<template>
  <!--  面包屑导航 -->
  <div class="getSubject">
    <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-input placeholder="查询机构名称" v-model="blurKey" @keyup.enter.native="onSubmit()"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" @keyup.enter="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-card>
        <div class="addhui">
          <div>添加合作机构结果</div>
          <div class="second-addhui">
            <div @click="sort()" class="first" :class="status?'addcolor':''">待审核</div>
            <div @click="sortd()" class="second" :class="!status?'addcolor':''">已审核</div>
          </div>
        </div>
        <div id="table1">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :empty-text="emptyText"
            style="width:100%"
            v-show="status"
            :header-cell-style="{background:'#BFDFF7',color:'#606266'}"
          >
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column prop="orgType" label="机构名称" align="center"></el-table-column>
            <el-table-column align="center" prop="orgName" label="公司名称">
              <template slot-scope="scope">
                <el-button type="text" @click="conpany(scope.row)" size="mini">{{scope.row.orgName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="memberLevel" label="会员等级"></el-table-column>
            <el-table-column align="center" prop="createUser" label="添加人"></el-table-column>
            <el-table-column align="center" prop="createTime" label="添加时间"></el-table-column>
            <el-table-column align="center" prop="orgName" label="审核">
              <template slot-scope="scope">
                <div class="aaaa">
                  <div @click="stad(scope.row)" class="first">通过</div>
                  <div @click="staa(scope.row)" class="second">驳回</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器开始 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="someOne.pagenum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="someOne.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            rel="table1"
            v-show="status"
          ></el-pagination>
          <!-- 分页器结束 -->
        </div>
        <div id="table2">
          <el-table
            ref="multipleTable"
            :data="tableDA"
            tooltip-effect="dark"
            :empty-text="emptyText"
            style="width: 100%"
            v-show="!status"
            :header-cell-style="{background:'#BFDFF7',color:'#606266'}"
          >
            <el-table-column align="center" label="序号" type="index"></el-table-column>

            <el-table-column align="center" prop="orgType" label="机构名称"></el-table-column>
            <el-table-column align="center" label="公司名称">
              <template slot-scope="scope">
                <el-button type="text" @click="conpany(scope.row)" size="mini">{{scope.row.orgName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="memberLevel" label="会员等级"></el-table-column>
            <el-table-column align="center" prop="createUser" label="添加人"></el-table-column>
            <el-table-column align="center" prop="createTime" label="添加时间"></el-table-column>
            <el-table-column align="center" prop="updateTime" label="通过时间"></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChanged"
            @current-change="handleCurrentChanged"
            :current-page="someOned.pagenumd"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="someOned.pagesized"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totald"
            v-show="!status"
            rel="table2"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 表格上传并查询代码 -->

    <!-- 公司信息弹窗的开始 -->
    <el-dialog title="公司信息" :visible.sync="companyDialogVisible" width="50%" center>
      <div class="compangdia">
        <div class="first_compangdia">公司名称</div>
        <div class="second_compangdia">{{userlist.orgName}}</div>
      </div>
      <div class="compangdia">
        <div class="first_compangdia">统一社会信用代码</div>
        <div class="second_compangdia">{{userlist.uscCode}}</div>
      </div>

      <div class="compangdia">
        <div class="first_compangdia">会员等级</div>
        <div class="second_compangdia">{{userlist.memberLevel}}</div>
      </div>
      <div class="compangdia">
        <div class="first_compangdia">管理员账号</div>
        <div class="second_compangdia">{{userlist.userName}}</div>
      </div>

      <div class="ender">
        <div class="endera" @click="editd">合同审核</div>
        <div class="endera" @click="editdd">营业执照审核</div>
      </div>
    </el-dialog>
    <!-- 公司信息弹窗的结束 -->
    <!-- 合格证上传 -->

    <el-dialog title="图片预览" :visible.sync="edutDialogVisible" width="50%" center>
      <viewer :images="photo">
        <img v-for="(src,index) in photo" :src="src" width="30%" height="30%" :key="index" />
      </viewer>
    </el-dialog>
    <el-dialog title="图片预览" :visible.sync="tDialogVisible" width="50%" center>
      <viewer :images="photod">
        <img v-for="(src,index) in photod" :src="src" width="30%" height="30%" :key="index" />
      </viewer>
    </el-dialog>
    <!-- 驳回理由 -->
    <el-dialog title="驳回" :visible.sync="rejectDialogVisible" width="50%" center>
      <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 6}"
        placeholder="请输入内容"
        v-model="reason"
      ></el-input>
      <div class="tijiao" @click="tijiao">提交</div>
    </el-dialog>
    <el-dialog title="通过" :visible.sync="successDialogVisible" width="50%" center>
      <span>请您确认是否通过。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="successDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="succ">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
export default {
  data() {
    return {
      someOne: {
        pagesize: 5,
        pagenum: 1,
      },
      someOned: {
        pagesized: 5,
        pagenumd: 1,
      },
      total: 0,
      totald: 0,
      successDialogVisible: false,
      Idss: "",
      rejectDialogVisible: false,
      edutDialogVisible: false, //合同审核显示隐藏
      tDialogVisible: false,
      companyDialogVisible: false, //控制账户信息的展示与隐藏
      photo: [],
      photod: [],
      limit: 10,
      fileList: [], // excel文件列表
      file: "",
      // 控制合格证的显示与隐藏
      phoyodialog: 1,
      //1的时候是合同支付上传  2的时候是营业执照上传
      value1: "",
      value: "",
      gridData: [],
      companyDialogVisible: false, //点击公司 展示公司信息
      status: true, //判断正式会员
      sta: "",
      //判断非正式会员
      emptyText: "请输入机构名称查询",
      file: "",
      reason: "", //驳回理由
      blurKey: "", //用户输入的

      tableData: [], //正式会员的参数
      tableDA: [], //非正式会员的参数
      multipleSelection: [],
      subjectId: "",
      userlist: "",
      member: "",

      addForm: {
        orgType: "", //类型
        orgName: "", //公司名称
        uscCode: "", //信用代码
        memberLevel: "", //会员等级
        loginName: "", //管理账号
        phone: "", //手机号
        password: "", //密码
        time: "", //时间
        dealPay: [], //合同支付凭证
        businessLicense: [], //营业执照上传
      },
    };
  },
  created() {
    this.onSubmit();
  },
  methods: {
    handleSizeChange(newSize) {
      this.someOne.pagesize = newSize;
      this.onSubmit();
    }, // 监听pagenum的改变
    handleCurrentChange(newPage) {
      this.someOne.pagenum = newPage;
      this.onSubmit();
    },
    handleSizeChanged(newSize) {
      this.someOned.pagesized = newSize;
      this.onSubmit();
    }, // 监听pagenum的改变
    handleCurrentChanged(newPage) {
      this.someOned.pagenumd = newPage;
      this.onSubmit();
    },
    // 点击合同做一个显示

    editd() {
      console.log(222);
      console.log(this.photo);
      this.edutDialogVisible = true;
    },
    editdd() {
      console.log(this.photo);
      this.tDialogVisible = true;
    },
    // 控制账户信息的展示与隐藏
    // conpany(e) {

    //   this.companyDialogVisible = true;
    // },
    async memberLevelchang(e) {
      console.log(e);
      const { data: res } = await this.$http({
        method: "get",
        url: "cooperationMember/getMemberLevel?level=" + e,
      });
      if (res.code !== 200) return this.$message.error("状态改变失败");

      this.$message.success("状态改变成功");
      console.log(res.data);
      // console.log(1112121)
    },

    quadd() {
      this.phoyodialogVisible = false;
    },
    Photoad() {
      this.phoyodialog = 2;
      this.phoyodialogVisible = true;
    },
    // 点击合格证上传
    Photoadd() {
      this.phoyodialog = 1;
      this.phoyodialogVisible = true;
    },

    // 点击公司出来弹窗
    async conpany(e) {
      console.log(1111);
      console.log(e.memberLevel);
      this.userlist = e;
      console.log(this.userlist);
      const { data: res } = await this.$http({
        method: "get",
        url: "cooperationMember/getMember/" + e.id,
      });
      if (res.code !== 200) return this.$message.error("获取公司信息失败");

      this.$message.success("获取公司信息成功");
      console.log(res.data);

      this.photo = eval(res.data.dealPay);
      this.photod = eval(res.data.businessLicense);
      console.log(this.photo);

      this.companyDialogVisible = true;
    },
    async succ() {
      this.sta = "approved";
      console.log(this.reason);
      const { data: res } = await this.$http({
        method: "post",
        url:
          "cooperationMember/approveCooperationMember?cooperationId=" +
          this.Id +
          "&status=" +
          this.sta +
          "&reason=" +
          this.reason,
      });
      this.adopt();
      this.$message.success("审核成功");
      this.successDialogVisible = false;
    },
    async stad(e) {
      this.Id = e.id;
      this.successDialogVisible = true;
      console.log(e);
    },
    // 封装通过审核的接口
    async adopt() {
      this.member = "pending";
      const { data: res } = await this.$http.get(
        "cooperationMember/getCooperationMemberList",
        {
          params: {
            blurKey: this.blurKey,
            status: this.member,
          },
        }
      );
      this.tableData = res.data.list;
    },
    // 点击驳回出来弹框

    staa(e) {
      this.Idss = e.id;
      console.log(this.Idss);
      this.rejectDialogVisible = true;
    },
    // 点击来回切换
    sort() {
      this.status = true;
    },
    sortd() {
      this.status = false;
    },

    // 点击开通会员提交
    async tijiao() {
      this.sta = "reject";
      console.log(this.reason);
      const { data: res } = await this.$http({
        method: "post",
        url:
          "cooperationMember/approveCooperationMember?cooperationId=" +
          this.Idss +
          "&status=" +
          this.sta +
          "&reason=" +
          this.reason,
      });
      this.reason = "";
      this.adopt();
      this.rejectDialogVisible = false;
      this.$message.success("驳回成功");
    },
    // 点击查询信息
    async onSubmit(e) {
      (this.tableData = []), (this.tableDA = []), console.log(this.status);
      if (this.status == true) {
        this.member = "pending";
        // 正式会员
        const { data: res } = await this.$http.get(
          "cooperationMember/getCooperationMemberList",
          {
            params: {
              blurKey: this.blurKey,
              status: this.member,
              limit: this.someOne.pagesize,
              offset: this.someOne.pagenum,
            },
          }
        );
        if (res.code !== 200) return this.$message.error("查询失败");
        if (res.data.total == 0) {
          this.emptyText = "无信息";
          return this.$message("无信息");
        }
        this.$message.success("查询成功");
        this.total = res.data.total;
        this.tableData = res.data.list;
        console.log(this.tableData);
      } else {
        this.member = "approved";

        const { data: res } = await this.$http.get(
          "cooperationMember/getCooperationMemberList",
          {
            params: {
              blurKey: this.blurKey,
              status: this.member,
              limit: this.someOned.pagesized,
              offset: this.someOned.pagenumd,
            },
          }
        );
        if (res.code !== 200) return this.$message.error("查询失败");
        if (res.data.total == 0) {
          this.emptyText = "无信息";
          return this.$message("无信息");
        }
        this.$message.success("查询成功");
        this.totald = res.data.total;

        this.tableDA = res.data.list;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.getSubject {
  .header {
    margin-top: 10px;
    width: 100%;
    .headerImg {
      width: 100%;
    }
  }
  .compangdia {
    display: flex;
    justify-content: center;
    align-items: center;
    .first_compangdia {
      flex: 1;
      height: 40px;
      background: #bfdff7;
      opacity: 1;
      text-align: center;
      line-height: 40px;
    }
    .second_compangdia {
      flex: 1;
      height: 40px;
      background: #f7f7f7;
      opacity: 1;
      text-align: center;
      line-height: 40px;
    }
  }
  .dia {
    .a_diaa_dia {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      .first_dia {
        width: 128px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
        opacity: 1;
        text-align: right;
      }
      .second_dia {
        width: 234px;
        height: 34px;
        background: #f4f7fe;
        border: 1px solid #cccccc;
        opacity: 1;
        border-radius: 6px;
        margin-left: 30px;
      }
    }
  }

  /deep/ .adopt_ {
    display: flex;
  }
  .addhui {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .second-addhui {
      display: flex;
      .first {
        width: 124px;
        height: 42px;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 100px 0px 0px 100px;
        line-height: 42px;
        text-align: center;
      }
      .second {
        width: 124px;
        height: 42px;

        opacity: 1;
        border: 1px solid #707070;
        border-radius: 0px 100px 100px 0px;
        line-height: 42px;
        text-align: center;
      }
      .addcolor {
        background: #1883e3;
        color: #fff;
      }
    }
  }
  /deep/.el-dialog__header {
    background-color: #1883e3;
  }
  /deep/.el-dialog__title {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
    opacity: 1;
  }

  .ender {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .endera {
      width: 122px;
      height: 25px;
      border: 1px solid #0083e3;
      opacity: 1;
      border-radius: 6px;
      text-align: center;
      line-height: 25px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;

      color: #0083e3;
      opacity: 1;
      margin-top: 18px;
    }
  }
  .tijiao {
    width: 115px;
    height: 34px;
    background: #0083e3;
    opacity: 1;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 34px;
    color: #ffffff;
    opacity: 1;
    margin: 20px auto;
    cursor: pointer;
  }
  // /deep/.el-input--suffix .el-input__inner {
  //   padding-right: 30px;
  //   margin-left: 30px;
  //   width: 240px;
  //   background: #f4f7fe;
  //   border: 1px solid #cccccc;
  //   opacity: 1;
  //   border-radius: 6px 0px 0px 6px;
  // }
  /deep/.el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    /* padding: 3px 10px; */
    width: 234px;
    height: 34px;
    background: #f4f7fe;
    border: 1px solid #cccccc;
    opacity: 1;
    border-radius: 6px;
    margin-left: 30px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    /* line-height: 17px; */
    color: #cccccc;
    opacity: 1;
  }
  .aaaa {
    display: flex;
    .first {
      width: 105px;
      height: 30px;
      border: 1px solid #707070;
      opacity: 1;
      line-height: 30px;
      text-align: center;
      background: #1883e3;
      border-radius: 100px 0px 0px 100px;
    }
    .second {
      width: 105px;
      height: 30px;
      border: 1px solid #707070;
      opacity: 1;
      line-height: 30px;
      text-align: center;
      border-radius: 0px 100px 100px 0px;
    }
  }
}
</style>
